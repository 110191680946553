/*
TODO:
CAROUSEL ITEM CONTAINERS
IPAD
*/

import React from "react"

import ProjectAnimations from "../../components/projects/projectAnimations"
import Slide from "../../components/projects/Slide"
import Spacer from "../../components/projects/Spacer"

import "./template-styles.scss"
import "./au-natural.scss"
import Img from "../../assets/images/au-natural-images"
import RightArrowSVG from "../../assets/svg/right-arrow.svg"

import useScreenTranslate from "../../hooks/useScreenTranslate"

import TransitionLink from "../../components/TransitionLink"

import ScrollDownArrow from "../../components/ScrollDownArrow"

import SEO from "../../components/seo"
import NavigationOrbs from "../../components/NavigationOrbs"

const black = "#212931"
const white = "#ffffff"
const beige = "#F3EDE2"
const green = "#9FC979"

const AuNaturalProject = () => {
  ProjectAnimations()

  const {
    scrollerSectionRef,
    scrollerScreenContainerRef,
    scrollerScreenRef,
    screenTranslate,
  } = useScreenTranslate({ durationInScreens: 3 })

  return (
    <div className="project au-natural">
      <SEO
        title="Au Natural"
        description="An extended brand platform and storytelling that took a sustainable
    New Zealand beauty product range to market. Design platform, website, photography,
    tone-of-voice, key messaging, copy content."
      />

      <NavigationOrbs />

      <Slide className="intro">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="au0" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: green, color: black }}
        >
          <div>
            <h1>Au Natural</h1>
            <p>
              Au Natural Skinfood takes care of your skin while looking after
              the interests of the planet. Au is taking a stand against
              single-use plastics with a highly effective product range that’s
              made from natural New Zealand ingredients and delivered in
              refillables and compostables. Subscribing to Au Natural means
              subscribing to better. We helped launch it. <br />
              <br />
              Design platform, website, photography, tone-of-voice, key
              messaging, copy content.
            </p>
          </div>
        </div>
      </Slide>

      <Slide className="section-1">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="au2" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: beige, color: black }}
        >
          <div>
            <p>
              An extended design look and feel with clean, beautiful photography
              and a strong storytelling tone-of-voice – a robust platform for Au
              Natural’s web and marketing in readiness for the launch.
            </p>
          </div>
        </div>
      </Slide>

      <Slide className="section-2">
        <Spacer />
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="au3" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-3">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="au4" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: green, color: beige }}
        >
          <div>
            <p>
              Kindness and authenticity are at the heart of their brand promise.
              Our beauty photoshoot shows our models just as they are. Real,
              natural, naturally beautiful. Their skin is untouched by
              Photoshop.{" "}
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-4">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="au5" className="h-full" />
        </div>
      </Slide>

      <div className="section-5 carousel-container section-wrapper">
        <div className="panel--w-full pin-once-scrolled">
          <div
            className="panel--w-half panel--h-screen centered-content "
            style={{ backgroundColor: green, color: beige }}
          >
            <div className="panel--text">
              <p>
                No nasties. No chemicals. Just pure, natural, New Zealand
                ingredients. So that’s how we showcased the products.
                Photography included ingredient, product and beauty shoots.
              </p>
            </div>
          </div>
        </div>

        <div className="items-container-parent pin-for-400 pin-fully-scrolled flex flex-wrap">
          <Spacer />
          <div
            className="items-container panel--w-half flex flex-col"
            style={{ backgroundColor: beige }}
          >
            <Img src="au6a" className="item" />
            <Img src="au6b" className="item" />
            <Img src="au6c" className="item" />
            <Img src="au6d" className="item" />
          </div>
        </div>
      </div>
      <Slide className="section-6">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="au1" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-7">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="au7" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-8">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content"
          style={{ backgroundColor: green, color: beige }}
        >
          <div className="panel--text">
            <p>
              Products and ingredients were photographed in forward-facing
              arrangements stacked one on top of the other – an interesting and
              engaging approach.{" "}
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-9">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="au8" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-10">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="au9" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-11">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="au10" className="h-full" />
        </div>
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="au11" className="h-full" />
        </div>
      </Slide>
      <div className="section-12 flex flex-wrap pin-for-400">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content "
          style={{ backgroundColor: green, color: beige }}
        >
          <div className="panel--text">
            <p>
              A responsive e-commerce website delivered a clean and smooth user
              experience that made shopping easy.{" "}
            </p>
          </div>
        </div>
      </div>
      <Spacer />
      <Slide className="section-13">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="au12" className="h-full" />
        </div>
      </Slide>
      <div className="section-14 flex flex-wrap pin-for-400">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content "
          style={{ backgroundColor: green, color: beige }}
        >
          <div className="panel--text">
            <p>
              Subscribe to Au Natural and you subscribe to better thinking. This
              idea flowed through the website key messaging and visuals.
            </p>
          </div>
        </div>
      </div>
      <Spacer />
      {/* <Slide className="section-15">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="au14" className="h-full" />w
        </div>
      </Slide> */}
      <div ref={scrollerSectionRef}>
        <Slide className="section-16">
          <div className="ipad panel--w-full panel--h-screen screen-container">
            <div className="screen-container__inner-container">
              <Img src="au14" className="screen-container__frame" />
              <div
                className="screen-container__screen "
                ref={scrollerScreenContainerRef}
              >
                <div
                  ref={scrollerScreenRef}
                  className="screen-container__translate"
                  style={{ transform: `translateY(${screenTranslate}%)` }}
                >
                  <Img src="au13" className="screen-container__frame" />
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </div>

      <Slide className="section-17">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: green, color: beige }}
        >
          <div>
            <p>
              The sustainability and authenticity stories around effective New
              Zealand-natural products resonate. Following a successful launch
              Au Natural Skinfood is in demand on both the eastern seaboard and
              west coast of America. <br />
              <br />
              <TransitionLink
                to="/projects/first-law/"
                color="#5856a0"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Next project
              </TransitionLink>
              <br />
              <TransitionLink
                to="/#projects"
                color="white"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block transform rotate-180"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Back to all projects
              </TransitionLink>
            </p>
          </div>
        </div>
      </Slide>
    </div>
  )
}

export default AuNaturalProject
