import { useEffect, useRef, useState } from "react"
import { useWindowSize, useWindowScroll } from "react-use"

const useScreenTranslate = ({ durationInScreens }) => {
  const { y: windowScrollY } = useWindowScroll()
  const { width: windowWidth, height: windowHeight } = useWindowSize()

  const scrollerSectionRef = useRef(null)
  const scrollerScreenContainerRef = useRef(null)
  const scrollerScreenRef = useRef(null)
  const [screenTranslate, setScreenTranslate] = useState(0)

  useEffect(() => {
    const returnTranslateValue = () => {
      const sectionScrollDelta =
        scrollerSectionRef.current.getBoundingClientRect().top + 400
      const containerHeight = scrollerScreenContainerRef.current.clientHeight
      const screenHeight = scrollerScreenRef.current.clientHeight

      if (sectionScrollDelta > 0) {
        return 0
      } else if (sectionScrollDelta < windowHeight * -durationInScreens) {
        return -100 * (1 - containerHeight / screenHeight)
      } else {
        const translate =
          (sectionScrollDelta / (windowHeight * -durationInScreens)) *
          -100 *
          (1 - containerHeight / screenHeight)
        return translate
      }
    }
    const screenTranslateAmount = returnTranslateValue()
    setScreenTranslate(screenTranslateAmount)
  }, [windowScrollY])
  return {
    scrollerSectionRef,
    scrollerScreenContainerRef,
    scrollerScreenRef,
    screenTranslate,
  }
}

export default useScreenTranslate
