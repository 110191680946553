import React from "react"
import { motion } from "framer-motion"
import { defaultTransition } from "../utils/easings"
import DownArrowSVG from "../assets/svg/down-arrow.svg"

const ScrollDownArrow = () => {
  return (
    <motion.h3
      animate={{
        y: ["0%", "0%", "40%", "0%"],
      }}
      transition={{
        duration: 2,
        ease: defaultTransition.ease,
        times: [0, 0.64, 0.82, 1],
        loop: Infinity,
        repeatDelay: 1,
      }}
      style={{ marginTop: "1em", cursor: "auto" }}
      className="scrolldown-btn"
    >
      <DownArrowSVG
        className="inline fill-current"
        style={{
          width: "0.6em",
          cursor: "auto",
        }}
      />
    </motion.h3>
  )
}

export default ScrollDownArrow
